<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <public-filter
          :action-methods="searchData"
          :action-filter="filterData"
          :reset-filter="resetSearch"
        />
      </b-card>
    </b-col>
    <b-col cols="12">
      <div
        v-if="interviewLoading"
        class="text-primary"
      >
        <loading />
      </div>
      <b-card no-body>
        <counters />
        <b-table
          class="font-small-3"
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
          hover
        >
          <template #cell(interview_number)="data">
            <b-button
              variant="flat-primary"
              size="sm"
              :to="'/interviews/view/' + data.item.interview_number"
            >
              {{ data.item.interview_number }}
            </b-button>
          </template>
          <template #cell(customer)="data">
            {{ data.item.customer }}
            <div v-if="data.item.company_name">
              <small class="text-warning font-small-2">{{ data.item.company_name }}</small>
            </div>
          </template>
          <template #cell(status)="data">
            <div>
              <b-badge :variant="data.item.status === '1'? 'success' : 'danger'">
                {{ data.item.status === '1'? 'Kapalı' : 'Açık' }}
              </b-badge>
            </div>
            <small class="text-muted font-small-2">{{ data.item.interview_status }}</small>
          </template>
          <template #cell(idate)="data">
            {{ data.item.idate? moment(data.item.idate).format('DD.MM.YYYY') : '-' }}
            <div :class="data.item.alert_date? 'text-success' : 'text-muted'">
              <div class="font-small-2">
                Hatırlatma
              </div>
              <div class="font-small-2">
                {{ data.item.alert_date? moment(data.item.alert_date).format('DD.MM.YYYY') : '-' }}
              </div>
            </div>
          </template>
        </b-table>
        <b-card-footer>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="dataCount"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
            <div class="text-muted font-small-2">
              Toplam {{ dataCount | toNumber }} adet görüşme bulundu.
            </div>
          </div>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BBadge,
} from 'bootstrap-vue'
import PublicFilter from '@/views/Interviews/Index/PublicFilter.vue'
import Loading from '@/layouts/components/Loading.vue'
import Counters from '@/views/Interviews/components/Counters.vue'

export default {
  name: 'Index',
  components: {
    Counters,
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BBadge,
    PublicFilter,
    Loading,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      currentPage: 1,
      perPage: 25,
      search: '',
      fields: [
        {
          key: 'interview_number',
          label: 'No',
          thStyle: { width: '100px', verticalAlign: 'middle' },
        },
        {
          key: 'customer',
          label: 'Müşteri',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'status',
          label: 'Durum',
          thStyle: { width: '200px', verticalAlign: 'middle' },
        },
        {
          key: 'meet',
          label: 'Temas',
          thStyle: { width: '200px', verticalAlign: 'middle' },
        },
        {
          key: 'username',
          label: 'Danışman',
          thStyle: { width: '180px', verticalAlign: 'middle' },
        },
        {
          key: 'idate',
          label: 'İlk Görüşme',
          thStyle: { width: '160px', verticalAlign: 'middle' },
        },
      ],
      dataQuery: {
        select: [
          'com_interview.interview_number AS interview_number',
          'com_interview.idate AS idate',
          'com_customer.name AS customer',
          'com_customer.company_name AS company_name',
          'com_interview.status AS status',
          'com_meet.title AS meet',
          'com_interview_type.title AS interview_type',
          'com_interview_status.title AS interview_status',
          'com_user.name AS username',
        ],
        where: {
          'com_interview.initial': 1,
        },
        limit: 25,
        start: 0,
        alertDate: true,
      },
    }
  },
  computed: {
    interviewLoading() {
      return this.$store.getters['interviews/getInterviewLoading']
    },
    dataList() {
      return this.$store.getters['interviews/getInterviews']
    },
    dataCount() {
      return this.$store.getters['interviews/getInterviewsCount']
    },
    quickSearch() {
      return this.$store.getters['interviews/getQuickSearch']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    searchData() {
      if (this.quickSearch.keyword.length > 0) {
        this.dataQuery.or_like = {
          'com_interview.interview_number': this.quickSearch.keyword,
          'com_customer.name': this.quickSearch.keyword,
          'com_customer.company_name': this.quickSearch.keyword,
          'com_customer.phone': this.quickSearch.keyword,
        }
        this.getDataList()
      }
    },
    resetSearch() {
      this.dataQuery.or_like = {}
      this.dataQuery.where = {
        'com_interview.initial': 1,
      }
      this.getDataList()
    },
    filterData(params) {
      const where = {
        'com_interview.initial': 1,
      }
      if (params.sdate) {
        where['com_interview.idate >='] = params.sdate
      }
      if (params.edate) {
        where['com_interview.idate <='] = params.edate
      }
      if (params.id_com_interview_type) {
        where['com_interview.id_com_interview_type'] = params.id_com_interview_type
      }
      if (params.id_com_brand) {
        where['com_interview.id_com_brand'] = params.id_com_brand
      }
      if (params.id_com_user) {
        where['com_interview.id_com_user'] = params.id_com_user
      }
      if (params.status !== null) {
        where['com_interview.status'] = params.status
      }
      this.dataQuery.where = where
      this.getDataList()
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      window.scrollTo(0, 0)
      this.$store.dispatch('interviews/interviewsList', this.dataQuery)
    },
  },
}
</script>
